import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import DropDownMenu from '@components/DropDownMenu';
import SpinnerModal from '@components/SpinnerModal';
import MUIDataTable from 'mui-datatables';
import ValidationsManager from '@components/ValidationsManager';
import DataCaptureService from '@services/DataCaptureService';
import ProfileManagementService from '@services/ProfileManagementService';
import SurveyService from '@services/SurveyService';
import { Link } from "react-router-dom";

import Chip from '@material-ui/core/Chip';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import NotStartedIcon from '@mui/icons-material/NotStarted';

import { CustomDialog, useDialog } from 'react-st-modal';
import ReAssignDialog from '@components/ReAssignDialog';
import SupportAlert from '@components/SupportAlert';

import styles from './capturesetup.module.css';
import Alert from '@mui/material/Alert';

class CaptureSetup extends Component {

    state = {
        loading: false,
        organisationsLoading: false,
        selectedSectorId: null,
        surveysUsers: null,
        allSectors: [],
        allOrganisationContacts: [],
        allOrganisationStatuses: [],
    }

    constructor() {
        super();
    }

    async componentDidMount() {
        this.setState({ loading: true });
        const captureFunctionEnabled = process.env.REACT_APP_RESEARCHER_DATA_CAPTURE_FUNCTION === 'ENABLED';

        if (captureFunctionEnabled) {
            this.setState({ questionnairePart: this.props.questionnairePart });

            const surveysUsers = await ProfileManagementService.getAllSurveysUsers();
            this.setState({ surveysUsers: surveysUsers });

            const sectors = await SurveyService.getAllSectors();
            sectors.map(item => this.state.allSectors.push({ key: item.id, value: item.name.trim() }));

            const organisationContacts = await DataCaptureService.getAllOrganisationContactsBySurveyCycleId(this.props.surveyCycleId);
            this.setState({ allOrganisationContacts: organisationContacts });

            // Stuff to be loaded goes here
        }
        this.setState({ loading: false });
    }

    onChangeFieldworker = (evt, organisationStateId) => {

        let value = null;

        if (evt.target.value !== undefined && evt.target.value != null && evt.target.value.length > 0) {
            value = evt.target.value
        }

        if (organisationStateId !== undefined && organisationStateId != null) {
            DataCaptureService.setOrganisationFieldworker(organisationStateId, value);
            let organisationStatuses = this.state.allOrganisationStatuses;
            organisationStatuses.filter((organisationStatus) => organisationStatus.organisationStateId == organisationStateId)[0].fieldworkerId = value;
            this.setState({ allOrganisationStatuses: organisationStatuses });
        }
    }

    onChangeContact = (evt, organisationStateId) => {

        let value = null;

        if (evt.target.value !== undefined && evt.target.value != null && evt.target.value.length > 0) {
            value = evt.target.value
        }

        let organisationStatuses = this.state.allOrganisationStatuses;
        let questionnaireTrackerId = organisationStatuses.filter((organisationStatus) => organisationStatus.organisationStateId == organisationStateId)[0].questionnaireTrackerId;
        if (questionnaireTrackerId !== undefined && questionnaireTrackerId != null) {
            DataCaptureService.setQuestionnaireTrackerFilledInBy(questionnaireTrackerId, value);
            organisationStatuses.filter((organisationStatus) => organisationStatus.organisationStateId == organisationStateId)[0].filledInBy = value;
            this.setState({ allOrganisationStatuses: organisationStatuses });
        }
    }

    onChangeSector = (evt) => {
        this.setState({ selectedSectorId: evt.target.value });
        this.reloadOrganisations(evt.target.value, this.props.surveyCycleId);
    }

    async onStart1stCapture(evt, organisationStateId) {

        const data = {
            "completed": false,
            "capturedById": this.props.capturedById,
            "questionnaireTrackerId": this.state.allOrganisationStatuses.filter(org => org.organisationStateId == organisationStateId)[0].questionnaireTrackerId
        };

        const dataCapture = await DataCaptureService.startCapture(data);

        let dataCaptureId = dataCapture[0].id;

        if (this.props.onSetupComplete !== undefined && this.props.onSetupComplete != null) {
            this.props.onSetupComplete(evt, this.state.allOrganisationStatuses.filter(org => org.organisationStateId == organisationStateId)[0], 'RESEARCHER_FIRST', dataCaptureId);
        }
    }

    onContinue1stCapture = (evt, organisationStateId, dataCaptureId) => {
        if (this.props.onSetupComplete !== undefined && this.props.onSetupComplete != null) {
            this.props.onSetupComplete(evt, this.state.allOrganisationStatuses.filter(org => org.organisationStateId == organisationStateId)[0], 'RESEARCHER_FIRST', dataCaptureId);
        }
    }

    async onStart2ndCapture(evt, organisationStateId) {

        const data = {
            "completed": false,
            "capturedById": this.props.capturedById,
            "questionnaireTrackerId": this.state.allOrganisationStatuses.filter(org => org.organisationStateId == organisationStateId)[0].questionnaireTrackerId
        };

        const dataCapture = await DataCaptureService.startCapture(data);

        let dataCaptureId = dataCapture[0].id;

        if (this.props.onSetupComplete !== undefined && this.props.onSetupComplete != null) {
            const validations = await ValidationsManager.initialiseValidations(organisationStateId);
            this.props.onSetupComplete(evt, this.state.allOrganisationStatuses.filter(org => org.organisationStateId == organisationStateId)[0], 'RESEARCHER_SECOND', dataCaptureId);
        }
    }

    async onContinue2ndCapture(evt, organisationStateId, dataCaptureId) {
        if (this.props.onSetupComplete !== undefined && this.props.onSetupComplete != null) {
            const validations = await ValidationsManager.initialiseValidations(organisationStateId);
            this.props.onSetupComplete(evt, this.state.allOrganisationStatuses.filter(org => org.organisationStateId == organisationStateId)[0], 'RESEARCHER_SECOND', dataCaptureId);
        }
    }

    async reloadOrganisations(sectorId, surveyCycleId) {
        this.setState({ organisationsLoading: true });

        try {
            sectorId = (sectorId !== undefined && sectorId != null && sectorId != '') ? sectorId : null;
            this.setState({ selectedSectorId: sectorId });

            const organisationStatuses = await DataCaptureService.getOrganisationStatusesBySectorIdAndSurveyCycleId(sectorId, surveyCycleId);
            this.setState({
                allOrganisationStatuses: organisationStatuses.filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
            });

            this.setState({ organisationsLoading: false });
        } catch (ex) {
            SupportAlert.displayAlert('Network Error', 'An unexpected network error occured while loading the organisations list. Please try again later.');
        }

        this.setState({ organisationsLoading: false });
    }

    getSurveysUserNameById(userId) {
        let firstname = this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0]?.firstname;
        let lastname = this.state.surveysUsers.filter(surveysUser => surveysUser.id == userId)[0]?.lastname;
        let name = (((firstname !== undefined && firstname !== null) ? firstname : '') + ' ' + ((lastname !== undefined && lastname !== null) ? lastname : ('')));
        name = ((firstname == undefined || firstname == null) && (lastname == undefined || lastname == null)) ? 'No User (Error)' : name;
        name = userId == 37 ? 'RDI Respondent' : name;
        return name;
    }

    getContactNameByOrganisationAndContactState(organisationStateId, contactStateId) {
        let orgContacts = this.state.allOrganisationContacts.filter(contact => contact.organisationStateId == organisationStateId);

        let contact = (contactStateId != null && orgContacts !== undefined && orgContacts != null && orgContacts.length > 0)
            ? orgContacts.filter(contact => contact.contactStateId == contactStateId)[0]
            : null;

        return contact != null ? (contact.title + ' ' + contact.firstname + ' ' + contact.lastname) : 'No Contact Selected';
    }



    handleReassignSave = (evt, reassignSurvey) => {
        try {
            const func = (async () => {
                const res = await DataCaptureService.saveSurveyReassignment(reassignSurvey);
            })([]);
            SupportAlert.displayAlert('Re-assign success', '1st Capture has been re-assigned.');
        } catch (ex) {
            SupportAlert.displayAlert('Network', 'An unexpected network error occured while saving your data. Please try again later.');
        }
    }
    async handleShowReassignDialog(evt, organisationStatus) {
        const result = await CustomDialog(
            <ReAssignDialog
                {...this.props}
                backButton={{ text: 'Cancel' }}
                nextButton={{ text: 'Save', onClick: this.handleReassignSave }}
                organisationStatus={organisationStatus}
            />,
            {
                className: styles.ReAssignDialog,
                title: 'Re Assign Data Capture',
                showCloseIcon: false,
                isFocusLock: true,
                isCanClose: true, // setting this to false prevents the dialog from being closed when clicking away
            }
        );
    }


    render() {
        if (this.state.loading === true) {
            return <SpinnerModal settings={true} />
        } else {
            let columns = [

                { label: "Organisation Name", name: "organisationName" },
                { label: "Receipt Number", name: "receiptNumber" },
                { label: "Assigned Fieldworker", name: "fieldworker" },

                { label: "Organisation Contact", name: "organisationContact" },
                { label: "1st Capture", name: "capture1" },
                { label: "2nd Capture", name: "capture2" },
                { label: "Re-assign 1st Capture", name: "reassign" },
            ];

            let tableData = [];

            this.state.allOrganisationStatuses
                .filter(orgStatus => (orgStatus.dispatched !== undefined && orgStatus.dispatched !== null && orgStatus.dispatched == true))
                .filter(orgStatus => orgStatus.sectorId == this.state.selectedSectorId)
                .sort((a, b) => (((a.parentOrganisationStateName !== undefined && a.parentOrganisationStateName !== null && a.parentOrganisationStateName !== '') ? a.parentOrganisationStateName + ' / ' : '') + a.organisationStateName).toUpperCase() > (((b.parentOrganisationStateName !== undefined && b.parentOrganisationStateName !== null && b.parentOrganisationStateName !== '') ? b.parentOrganisationStateName + ' / ' : '') + b.organisationStateName).toUpperCase() ? 1 : -1)
                .map(organisationStatus => {
                    let organisationContactList = this.state.allOrganisationContacts.filter((organisationContact) => organisationContact.organisationStateId == organisationStatus.organisationStateId);

                    let organisationContacts = [];

                    organisationContactList.map(item => {
                        organisationContacts.push({ key: item.contactStateId, value: this.getContactNameByOrganisationAndContactState(organisationStatus.organisationStateId, item.contactStateId) });
                    });

                    let thisSurveysUsers = [];

                    this.state.surveysUsers.filter((surveysUser) => surveysUser.active == true).map(item => {
                        thisSurveysUsers.push({ key: item.id, value: this.getSurveysUserNameById(item.id) });
                    });

                    let org_name = ((organisationStatus.parentOrganisationStateName !== undefined && organisationStatus.parentOrganisationStateName !== null && organisationStatus.parentOrganisationStateName !== '') ? organisationStatus.parentOrganisationStateName + ' / ' : '') + organisationStatus.organisationStateName;

                    let row = {
                        organisationName: org_name,
                        receiptNumber: ((organisationStatus.receiptNumber != null) ? organisationStatus.receiptNumber : 'Not received'),
                        fieldworker: (organisationStatus.fieldworkerId !== undefined && organisationStatus.fieldworkerId != null) ? this.getSurveysUserNameById(organisationStatus.fieldworkerId) : 'Not assigned',
                        organisationContact: <DropDownMenu
                            name={'contact'}
                            label={'Contact'}
                            value={organisationStatus.filledInBy}
                            onChange={(evt) => this.onChangeContact(evt, organisationStatus.organisationStateId)}
                            showKeys={false}
                            menuOptions={organisationContacts.sort((a, b) => a.value > b.value ? 1 : -1)}
                            placeholder='Not Selected'
                            required
                        />,
                        capture1: (organisationStatus.datacapture1Id == null
                            ? (organisationStatus.filledInBy != null
                                ? ((organisationStatus.receiptNumber != null && organisationStatus.questionnairePartStatus == 'CHECKED_READY_FOR_CAPTURE')
                                    ?

                                    <Chip variant="outlined" icon={<PlayCircleOutlineIcon />} label="Start Capture"
                                        onClick={evt => this.onStart1stCapture(evt, organisationStatus.organisationStateId)}
                                    />
                                    : 'Questionnaire not received and checked '
                                )
                                : 'Select contact to start capture'
                            )
                            : (organisationStatus.datacapture1Completed
                                ? ('Completed by ' + this.getSurveysUserNameById(organisationStatus.datacapture1CapturedById))
                                : ((organisationStatus.datacapture1CapturedById == this.props.capturedById)
                                    ? (
                                        <Chip variant="outlined" icon={<NotStartedIcon />} label="Resume Capture"
                                            onClick={evt => this.onContinue1stCapture(evt, organisationStatus.organisationStateId, organisationStatus.datacapture1Id)}
                                        />

                                    )
                                    : ('Capture in progress by ' + this.getSurveysUserNameById(organisationStatus.datacapture1CapturedById))
                                )
                            )
                        ),
                        capture2: (organisationStatus.datacapture2Id == null
                            ? (organisationStatus.filledInBy != null
                                ? ((organisationStatus.datacapture1Completed && organisationStatus.datacapture1CapturedById != this.props.capturedById)
                                    ? <Chip variant="outlined" icon={<PlayCircleOutlineIcon />} label="Start Capture"
                                        onClick={evt => this.onStart2ndCapture(evt, organisationStatus.organisationStateId)}
                                    />
                                    : 'Not started'
                                )
                                : (organisationStatus.datacapture1Completed
                                    ? 'Select contact to start capture'
                                    : 'Not started'
                                )
                            )
                            : (organisationStatus.datacapture2Completed
                                ? ('Completed by ' + this.getSurveysUserNameById(organisationStatus.datacapture2CapturedById))
                                : ((organisationStatus.datacapture2CapturedById == this.props.capturedById)
                                    ?
                                    <Chip variant="outlined" icon={<NotStartedIcon />} label="Resume Capture"
                                        onClick={evt => this.onContinue2ndCapture(evt, organisationStatus.organisationStateId, organisationStatus.datacapture2Id)}
                                    />


                                    : ('Capture in progress by ' + this.getSurveysUserNameById(organisationStatus.datacapture2CapturedById))
                                )
                            )
                        ),
                        reassign:
                            (
                                ((organisationStatus.datacapture1Completed && organisationStatus.datacapture2Completed) || organisationStatus.receiptNumber == null) ?
                                    <Chip variant="outlined" icon={<SwapHorizontalCircleIcon />} label="Re-assign" disabled />
                                    : <Chip variant="outlined" icon={<SwapHorizontalCircleIcon />} label="Re-assign" onClick={evt => this.handleShowReassignDialog(evt, organisationStatus)} />

                            )

                    }
                    tableData.push(row);
                });

            let options = {
                download: false,
                print: false,
                padding: 'none',
                size: 'small',
                rowsPerPage: [10],
                rowsPerPageOptions: [10, 20, 50, 100],
                jumpToPage: true,
                selectableRowsHideCheckboxes: true,
            };

            const captureFunctionEnabled = process.env.REACT_APP_RESEARCHER_DATA_CAPTURE_FUNCTION === 'ENABLED';
            const dataCaptureDisabledMessage = process.env.REACT_APP_RESEARCHER_DATA_CAPTURE_FUNCTION_DISABLED_MESSAGE;

            return (
                <div className={styles.captureSetupContainer}>
                    <div className={styles.featuredDropDown}>
                        <span className={styles.featuredTitleHeader}><h2>{'Data Capture'}</h2></span>
                        <hr />
                        <Grid container item xs={15}>
                            {captureFunctionEnabled
                                ? <DropDownMenu
                                    name={'sector'}
                                    label={'Sector'}
                                    onChange={(evt) => this.onChangeSector(evt)}
                                    showKeys={false}
                                    menuOptions={this.state.allSectors.sort((a, b) => a.value > b.value ? 1 : -1)}
                                    placeholder='Select Sector'
                                    required
                                />
                                : <Alert variant="outlined" severity="warning">{dataCaptureDisabledMessage}</Alert>
                            }
                        </Grid>
                        <br />
                    </div>
                    <div className={styles.featuredItem}>

                        <Grid container item xs={15} >
                            {(this.state.selectedSectorId != null && this.state.organisationsLoading == false)
                                ? <div className={styles.flex_row_container}>
                                    <MUIDataTable
                                        title={<div className={styles.featuredItem}><h1 className={styles.featuredTitleHeader}>{'Organisations'.toUpperCase()}</h1></div>}
                                        data={tableData}
                                        columns={columns}
                                        options={options}
                                    />
                                </div>
                                : (
                                    this.state.organisationsLoading == true
                                        ? <SpinnerModal settings={this.state.organisationsLoading} />
                                        : <div />
                                )
                            }
                        </Grid>
                    </div>
                </div>
            )
        }
    }
}

export default CaptureSetup;